

export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "Nosotros",
        path: '/about'
    },
    {
        name: "Blog",
        path: '/blog'
    },
    {
        name: "Servicios Funerarios",
        path: '/funeralServices'
    },
    {
        name: "Salas de Velación Quito",
        path: '/wakeRooms'
    },
    {
        name: "Salas de Velación Guayaquil",
        path: '/WakeRoomsGYE'
    },
    {
        name: "Contacto",
        path: '/contact'
    }
]







export const programs = [
    {
        id: 1,
        title: "AUXILIO FUNERARIO PARA INHUMACIÓN",
        info: "Es el derecho que tenemos todos los afiliados, jubilados y/o beneficiarios de montepío",
        path: "/funeralServices"
    },
    {
        id: 2,
        title: "AUXILIO FUNERARIO PARA CREMACIÓN",
        info: "Es el derecho que tenemos todos los afiliados, jubilados y/o beneficiarios de montepío",
        path: "/funeralServices"
    },
    {
        id: 3,
        title: "CAPILLA ARDIENTE",
        info: "Damos el servicio de capilla ardiente que sirve para honrar y despedir en el domicilio de forma pública",
        path: "/funeralServices"
    },
    {
        id: 4,
        title: "INHUMACIÓN Y CREMACIÓN PARA PARTICULARES",
        info: "Nosotros como funeraria podemos brindar el servicio funerario para personas particulares",
        path: "/funeralServices"
    }
]








export const about = [
    {
        id: 1,
        title: "Salas de Velación QUITO",
        desc: "Nuestras salas de velación en Quito cuentan con salas premium y vip, cuentan con capilla, cafetería, sala de estar, baños privados, Wifi, equipos para transmisión de velación, misa virtual, parqueaderos y servicio de seguridad privada."
    },
    {
        id: 2,
        title: "Salas de Velación GUAYAQUIL",
        desc: "Nuestras salas de velación en GUAYAQUIL cuentan con cuatro salas, con cafetería, sala de estar, baños privados, equipos para transmisión de velación, misa virtual, Wifi, parqueaderos y servicio de seguridad privada."
    }
]









export const faqs = [
    {
        id: 1,
        question: "¿Cuánto cubre el IESS por gastos funerarios?",
        answer: "Para 2023, el Instituto Ecuatoriano de Seguridad Social (IESS) estableció el monto de 1406,67 dólares para el auxilio de funerales."
    },
    {
        id: 2,
        question: "¿Exequiales IESS es una empresa solo para afiliados/ jubilados al Instituto Ecuatoriano de Seguridad Social?",
        answer: "Nosotros también ofrecemos nuestros servicios al público en general."
    },
    {
        id: 3,
        question: "¿Qué es el Auxilio de Funerales?",
        answer: "Es la entrega de una ayuda económica a los deudos del  afiliado, jubilado o beneficiario de montepío fallecido que otorga El Instituto Ecuatoriano de Seguridad Social (IESS) para garantizar la tranquilidad de la familia en esos difíciles momentos."
    },
    {
        id: 4,
        question: "¿Los jubilados/ afiliados al Seguro Social Campesino también tienen derecho al Auxilio Funerario?",
        answer: "El Seguro Social Campesino entrega únicamente prestaciones de salud y el pago de pensiones."
    },
    {
        id: 5,
        question: "Acabo de perder a un familiar ¿Qué debo hacer?",
        answer: "Contáctanos de inmediato al número 099 800 5771 en Quito y 098 766 8617 a Guayaquil, nosotros atendemos 24 horas los 365 días del año."
    },
    {
        id: 6,
        question: "¿Qué cubre el IESS por fallecimiento?",
        answer: "Esta prestación cubre los servicios de: velación, carroza,  inhumación o cremación, cofre mortuorio o urna cineraria,  traslados a la sala de velación y al camposanto, servicio religioso y trámites legales."
    }
]








export const testimonials = [
    {
        id: 1,
        name: "Paulina Gahui",
        quote: "En un momento dificil exequiales del iess estuvo ahi para ayudar y aliviar el dolor que sentiamos en ese momento. Aparte de ser una empresa del iess nos brindo todos los beneficios que el afiliado necesito en ese momento.",
        job: "",
        //avatar: require("./images/")
    },
    {
        id: 2,
        name: "Sara Recalde",
        quote: "Recomiendo por experiencia, un excelente servicio. Calidad excelente de los trabajadores. No existe inconveniente en la cobertura de gastos pues trabajan con los fondos de auxilio de funerales que nos otorga el IESS.",
        job: "",
        //avatar: require("./images/avatar2.jpg")
    },
    {
        id: 3,
        name: "Jennifer Castillo",
        quote: "Recomiendo sus servicios, tienen instalaciones amplias y brindan una guía completa sobre todos los trámites legales.",
        job: "",
        //avatar: require("./images/avatar3.jpg")
    },
    {
        id: 4,
        name: "Janneth Salgado",
        quote: "El personal es muy cálido y siempre dispuesto a ayudar",
        job: "",
       // avatar: require("./images/avatar4.jpg")
    },
    {
        id: 5,
        name: "Carlos Erazo",
        quote: "Un servicio muy bueno. La atención muy amable.",
        job: "",
       // avatar: require("./images/avatar5.jpg")
    }
]







export const plans = [
    {
        id: 1,
        name: 'Servicio VIP Cremación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Cofre', available: true},
            {feature: '> Velación por 24 horas', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Cafetería VIP', available: true},
            {feature: '> Servicio religioso con acompañamiento musical', available: true},
            {feature: '> Libro de condolencias', available: true},
            {feature: '> Tarjetas de agradecimiento', available: true},
            {feature: '> Cremación', available: true},
            {feature: '> Urna cenizaria', available: true}
        ]
    },
    {
        id: 2,
        name: 'Servicio VIP Inhumación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Cofre de madera', available: true},
            {feature: '> Velación por 24 horas', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Cafetería VIP', available: true},
            {feature: '> Servicio religioso con acompañamiento musical', available: true},
            {feature: '> Libro de condolencias', available: true},
            {feature: '> Tarjetas de agradecimiento', available: true}
        ]
    },
    {
        id: 3,
        name: 'Servicio PREMIUM Cremación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '>Tanatopraxia', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Cofre', available: true},
            {feature: '> Velación por 24 horas', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Cafetería Premium', available: true},
            {feature: '> Servicio religioso con acompañamiento musical', available: true},
            {feature: '> Misa primer mes con acompañamiento musical', available: true},
            {feature: '> Libro de condolencias', available: true},
            {feature: '> Tarjetas de agradecimiento', available: true},
            {feature: '> Cremación', available: true},
            {feature: '> Urna cenizaria', available: true}
        ]
    },
    {
        id: 4,
        name: 'Servicio PREMIUM Inhumación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Cofre', available: true},
            {feature: '> Velación por 24 horas', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Cafetería Premium', available: true},
            {feature: '> Servicio religioso con acompañamiento musical', available: true},
            {feature: '> Misa primer mes con acompañamiento musical', available: true},
            {feature: '> Libro de condolencias', available: true},
            {feature: '> Tarjetas de agradecimiento', available: true}
        ]
    },
    {
        id: 4,
        name: 'Capilla Ardiente Cremación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Cofre', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Libro de Condolencias', available: true},
            {feature: '> Servicio Religioso', available: true},
            {feature: '> Accesorios para capilla ardiente', available: true},
            {feature: '> Cremación', available: true},
            {feature: '> Urna cenizaria', available: true}
        ]
    },
    {
        id: 4,
        name: 'Capilla Ardiente Inhumación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Cofre', available: true}
        ]
    }
]








