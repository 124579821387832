import Header from '../../components/Header'
import HeaderImage from '../../Imagenes/INSTALACIONES/MGL_0606.jpg'
import {MdEmail} from 'react-icons/md'
import {BsMessenger} from 'react-icons/bs'
import {IoLogoWhatsapp} from 'react-icons/io'
import Form from '../../components/Form'
import React, { useEffect } from 'react';
import './contact.css';




const Contact = () => {

  useEffect(() => { /*Para ver desde el principio de la página */
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <Header title="Contactános" image={HeaderImage}>
    Contamos con los mejores asesores comerciales para brindarte un servicio de calidad, 
    pregunta sobre nuestros planes de asistencia funeraria.
    </Header>

    <section className="main_contact">
      <div className="contact__ubicacion">
        <div className='contact__text'>
        <h2 className='title_contact'>UBICACIÓN SALAS QUITO</h2>
          <p>
          Av. Eloy Alfaro N43-21 y Río Coca (esquina)
          <br/>
          099 800 5771 – (02) 2 272 803
          </p>
          <Form/>
        </div>
        <div className='contact__map'>
        <iframe className="mapa1" title='mapa1' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.8017860934374!2d-78.47109152510198!3d-0.16495043542835097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d591e74695106d%3A0x64fb9e594c99ccf6!2sExequiales%20IESS!5e0!3m2!1ses!2sec!4v1682519250384!5m2!1ses!2sec"  loading="lazy"></iframe>
        </div>
      </div>
      </section>
      <hr />
      <section className="main_contact">
      <div className="contact__ubicacion">
        <div className='contact__map'>
        <iframe className="mapa1" title='mapa2' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.8017860934374!2d-78.47109152510198!3d-0.16495043542835097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d591e74695106d%3A0x64fb9e594c99ccf6!2sExequiales%20IESS!5e0!3m2!1ses!2sec!4v1682519250384!5m2!1ses!2sec"  loading="lazy"></iframe>
        </div>
        <div className='contact__text'>
          <div className='elements_contact'>
            <div>
            <h2 className='title_contact'>UBICACIÓN SALAS GUAYAQUIL</h2>
            </div>
            <div>
            <p>
            Pedro José Menéndez Gilbert S/N y Julián Coronel. (Junto al Cuerpo de Bomberos)
            </p>
            </div>
            <div>
            <p>
            098 766 8617 – (04) 239 7302
            </p>
            </div>
          
          </div>
          <Form/>
        </div>
      </div>
      </section>
      <div className="container contact__container">
        <div className="contact__wrapper">
          <a href="mailto:jvinueza@exequialesiess.com" target="_blank" rel="noreferrer noopener"><MdEmail/></a>
          <a href="http://m.me/ExequialesIESSEcuador" target="_blank" rel="noreferrer noopener"><BsMessenger/></a>
          <a href="https://wa.me/+593998005771" target="_blank" rel="noreferrer noopener"><IoLogoWhatsapp/></a>
        </div>
      </div>

    
    </>
  )
}

export default Contact
