import MainHeader from '../../components/MainHeader';
import Programs from '../../components/Programs';
import About from '../../components/About';
import FAQs from '../../components/FAQs';
import Testimonials from '../../components/Testimonials';
import React, { useEffect } from 'react';
import './home.css'

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <MainHeader/>
    <Programs/>
    <About/>
    <FAQs path="/lfaqs"/>
    <Testimonials/>
    </>
  )
}

export default Home


