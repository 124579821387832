import React from 'react';
import './WhatsAppWidget.css';
import whatsapp from '../Imagenes/whatsapp.png'

const WhatsAppWidget = () => {
  return (
    <div className="whatsapp-widget">
      <a href="https://wa.me/+593998005771" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="WhatsApp" />
      </a>
    </div>
  );
};

export default WhatsAppWidget;
