import Header from '../../components/Header'
import HeaderImage from '../../Imagenes/IMG_38211.JPG'
import ImageEternidad1 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA ETERNIDAD/WhatsApp Image 2021-02-08 at 09.43.23 (1).jpeg'
import ImageEternidad2 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA ETERNIDAD/WhatsApp Image 2021-03-22 at 10.51.22.jpeg'
import ImageEternidad3 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA ETERNIDAD/IMG_4388.JPG'
import ImageEsperanza1 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA ESPERANZA/WhatsApp Image 2021-02-08 at 09.43.23 (1).jpeg'
import ImageEsperanza2 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA ESPERANZA/IMG_4382.JPG'
import ImageEsperanza3 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA ESPERANZA/IMG_3823.JPG'
import ImageFe1 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA FE/WhatsApp Image 2021-02-08 at 09.43.23 (2).jpeg'
import ImageFe2 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA FE/IMG_3943.JPG'
import ImageFe3 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA FE/IMG_4389.JPG'
import ImageFraternidad1 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA FRATERNIDAD/IMG_3822.JPG'
import ImageFraternidad2 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA FRATERNIDAD/IMG_4383.JPG'
import ImageFraternidad3 from '../../Imagenes/página web/SALAS GUAYAQUIL/SALA FRATERNIDAD/IMG_4390.JPG'
import React, { useEffect } from 'react';
import './wakeRooms.css'

const WakeRooms = () => {

  useEffect(() => { /*Para ver desde el principio de la página */
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Header title="Salas de Velación GUAYAQUIL" image={HeaderImage}>
    Contamos con 4 salas premium con capacidad para 120 personas. 

    </Header>
    <div className='title_container'>
    <h1>Sala Eternidad</h1>
    <p>Capacidad para 120 personas - Cafetería - 2 Salas de descanso - Baños privados - Equipos de transmisión: velación y misa virtual - WIFI - Parqueaderos - Servicio de seguridad privada</p>
    </div>
    <section className='wakeRooms__container'>
    <div className="gallery">
    <img src={ImageEternidad2} alt="Instalación 2" />
      <img src={ImageEternidad1} alt="Instalación 1" />
      <img src={ImageEternidad3} alt="Instalación 3" />
    </div>
    </section>

    <br/>

    <div className='title_container'>
    <h1>Sala Esperanza</h1>
    <p>Capacidad para 120 personas - Cafetería - 2 Salas de descanso - Baños privados - Equipos de transmisión: velación y misa virtual - WIFI - Parqueaderos - Servicio de seguridad privada</p>
    </div>
    <section className='wakeRooms__container'>
    <div className="gallery">
      <img src={ImageEsperanza3} alt="Instalación 3" />
      <img src={ImageEsperanza1} alt="Instalación 1" />
      <img src={ImageEsperanza2} alt="Instalación 2" />
    </div>
    </section>

    <br/>

    <div className='title_container'>
    <h1>Sala Fé</h1>
    <p>Capacidad para 120 personas - Cafetería - 2 Salas de descanso - Baños privados - Equipos de transmisión: velación y misa virtual - WIFI - Parqueaderos - Servicio de seguridad privada</p>
    </div>
    <section className='wakeRooms__container'>
    <div className="gallery">
      <img src={ImageFe3} alt="Instalación 1" />
      <img src={ImageFe2} alt="Instalación 2" />
      <img src={ImageFe1} alt="Instalación 3" />
    </div>
    </section>

    <br/>

    <div className='title_container'>
    <h1>Sala Fraternidad</h1>
    <p>Capacidad para 120 personas - Cafetería - 2 Salas de descanso - Baños privados - Equipos de transmisión: velación y misa virtual - WIFI - Parqueaderos - Servicio de seguridad privada</p>
    </div>
    <section className='wakeRooms__container'>
    <div className="gallery">
      <img src={ImageFraternidad3} alt="Instalación 1" />
      <img src={ImageFraternidad2} alt="Instalación 3" />
      <img src={ImageFraternidad1} alt="Instalación 4" />
    </div>
    </section>
    
    
    </>
    
  )
}

export default WakeRooms
