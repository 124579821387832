import Header from '../../components/Header'
import HeaderImage from '../../Imagenes/INSTALACIONES/DSC_3273.jpg'
import ImageSala1 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 1/sala1.jpg'
import ImageSala12 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 1/sala11.jpg'
import ImageSala13 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 1/sala12.jpg'
import ImageSala14 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 1/sala13.jpg'
import ImageSala2 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 2/sala 2.jpg'
import ImageSala23 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 2/sala 21.jpg'
import ImageSala24 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 2/sala 23.jpg'
import ImageSala3 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 3/sala 3.jpg'
import ImageSala32 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 3/sala 31.jpg'
import ImageSala33 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 3/sala 32.jpg'
import ImageSala35 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 3/sala 33.jpg'
import ImageSala4 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 4/sala 4.jpg'
import ImageSala43 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 4/sala 41.jpg'
import ImageSala45 from '../../Imagenes/página web/SALAS QUITO NORTE/SALA 4/sala 42.jpg'
import React, { useEffect } from 'react';
import './wakeRooms.css'

const WakeRooms = () => {

  useEffect(() => { /*Para ver desde el principio de la página */
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Header title="Salas de Velación" image={HeaderImage}>
      Contamos con 2 salas vip y 2 salas premium con capacidad para 80 y 120 personas y nuestras instalaciones también cuenta con Capilla. 
    </Header>
    <section className='wakesrooms'>
    <div className='title_container'>
    <h1 className='title_sala'>SALA 1 VIP</h1>
    <p>Capacidad para 80 personas - Cafetería - Sala de estar - Baños privados - Equipos de transmisión: velación y misa virtual - WIFI - Parqueaderos - Servicio de seguridad privada</p>
    </div>
    <section className='wakeRooms__container'>
    <div className="gallery">
      <img src={ImageSala1} alt="Instalación 1" />
      <img src={ImageSala12} alt="Instalación 2" />
      <img src={ImageSala13} alt="Instalación 3" />
      <img src={ImageSala14} alt="Instalación 4" />
    </div>
    </section>

    <br/>

    <div className='title_container'>
    <h1>SALA 2 VIP</h1>
    <p>Capacidad para 80 personas - Cafetería - Sala de estar - Baños privados - Equipos de transmisión: velación y misa virtual - WIFI - Parqueaderos - Servicio de seguridad privada</p>
    </div>
    <section className='wakeRooms__container'>
    <div className="gallery">
      <img src={ImageSala2} alt="Instalación 1" />
      <img src={ImageSala23} alt="Instalación 2" />
      <img src={ImageSala24} alt="Instalación 3" />
    </div>
    </section>

    <br/>

    <div className='title_container'>
    <h1>SALA 3 PREMIUM</h1>
    <p>Capacidad para 120 personas - Cafetería - Sala de estar - Baños privados - Equipos de transmisión: velación y misa virtual - WIFI - Parqueaderos - Servicio de seguridad privada</p>
    </div>
    <section className='wakeRooms__container'>
    <div className="gallery">
      <img src={ImageSala3} alt="Instalación 1" />
      <img src={ImageSala32} alt="Instalación 2" />
      <img src={ImageSala33} alt="Instalación 3" />
      <img src={ImageSala35} alt="Instalación 4" />
    </div>
    </section>

    <br/>

    <div className='title_container'>
    <h1>SALA 4 PREMIUM</h1>
    <p>Capacidad para 120 personas - Cafetería - Sala de estar - Baños privados - Equipos de transmisión: velación y misa virtual - WIFI - Parqueaderos - Servicio de seguridad privada</p>
    </div>
    <section className='wakeRooms__container'>
    <div className="gallery">
      <img src={ImageSala4} alt="Instalación 1" />
      <img src={ImageSala32} alt="Instalación 2" />
      <img src={ImageSala43} alt="Instalación 3" />
      <img src={ImageSala45} alt="Instalación 4" />
    </div>
    </section>
    </section>
    
    
    
    </>
    
  )
}

export default WakeRooms
