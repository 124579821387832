import Header from '../../components/Header'
import HeaderImage from '../../Imagenes/INSTALACIONES/DSC_3301.jpg'
import StoryImage from '../../Imagenes/empresa.jpg'
import VisionImage from '../../Imagenes/visionexe.jpg'
import MissionImage from '../../Imagenes/misionexe.jpg'
import React, { useEffect } from 'react';
import './about.css'

const About = () => {

  useEffect(() => { /*Para ver desde el principio de la página */
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Header title="Sobre Nosotros" image={HeaderImage}>
    Comprendemos y compartimos los sentimientos de los demás, la empatía nos 
    permite ver las cosas desde la perspectiva del otro en vez de la nuestra.
    </Header>

    <section className="about__story">
      <div className="container about__story-container">
        <div className='about__section-image'>
          <img src={StoryImage} alt="Our Story Imagen" />
        </div>
        <div className="about__section-content">
          <h1>Nuestra Historia</h1>
          <p>
          Exequiales IESS es una compañia acreditada por el Instituto Ecuatoriano de 
          Seguridad social para brindar servicios funerarios haciendo uso del Fondo de 
          Auxilio para Funerales. Todos los pensionistas de jubilación, pensionistas de 
          montepío, y afiliados al IESS que tengan por lo menos 6 aportes mensuales 
          dentro de los últimos 12 meses anteriores a su fallecimiento, tienen derecho 
          al Fondo de Auxilio para Funerales.
          </p>
        </div>
      </div>
    </section>
    
    <section className="about__vision">
      <div className="container about__vision-container">
        <div className="about__section-content">
          <h1>Nuestra Visión</h1>
          <p>
          Ser una empresa líder en la prestación de servicios funerarios a nivel nacional 
          mediante nuestra atención de calidad y servicio integral basado en el respeto, 
          ética y cultura organizacional orientada al cliente.
          </p>
        </div>
        <div className='about__section-image'>
          <img src={VisionImage} alt="Our Vision Imagen" />
        </div>
      </div>
    </section>

    <section className="about__mission">
      <div className="container about__mission-container">
        <div className='about__section-image'>
          <img src={MissionImage} alt="Our Mission Imagen" />
        </div>
        <div className="about__section-content">
          <h1>Nuestra Misión</h1>
          <p>
          Ofrecer servicios funerarios de alta calidad a los afiliados, 
          jubilados, pensionistas de montepío del IESS y público en general; 
          pensando siempre en su tranquilidad, seguridad, paz y confianza
          </p>
        </div>
      </div>
    </section>

    </>
  )
}

export default About