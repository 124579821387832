
import Image from '../Imagenes/piz.png'
import Image1 from '../Imagenes/logo morado.png'

const MainHeader = () => {
  return (
    <header className="main__header">
      <div className="container main__header-container">
        <div className="main__header-left">
          <h1>
          <img src={Image1} alt="Imagen del menu del encabezado" />
          </h1>
          <p>En momentos difíciles, estamos aquí para brindar apoyo y 
            guía en la planificación de servicios funerarios significativos 
            y personalizados.
          </p>
          
          <h2>ATENCIÓN 24 HORAS</h2>
          <h3 id='h4num'>Quito Norte:<br/> <a href="tel: +593998005771">099 800 5771</a>  –  (02) 2 272 803  <br/> <br/> 
          Guayaquil:<br/> <a href="tel: +593987668617">098 766 8617</a>  – (04) 239 7302 <br/>
          </h3>
        </div>
        <div className="main__header-right">
          <div className="main__header-circle">
            <div className="main__header-image">
              <img src={Image} alt="Imagen del menu del encabezado" />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default MainHeader
