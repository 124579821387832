import Header from '../../components/Header'
import HeaderImage from '../../Imagenes/INSTALACIONES/DSC_3273.jpg'
import React, { useEffect } from 'react';
import './funeralServices.css';
import icon1 from '../../Imagenes/Icons/arreglos florales.png' 
import icon2 from '../../Imagenes/Icons/cafeteria.png' 
import icon3 from '../../Imagenes/Icons/cofre.png' 
import icon4 from '../../Imagenes/Icons/cremacion.png' 
import icon5 from '../../Imagenes/Icons/salas de velacion.png' 
import icon6 from '../../Imagenes/Icons/servicio religioso.png' 
import icon7 from '../../Imagenes/Icons/tanatopraxia.png' 
import icon8 from '../../Imagenes/Icons/traslado.png' 
import icon9 from '../../Imagenes/Icons/capilla.png' 
import icon10 from '../../Imagenes/Icons/tramites.png' 
import icon11 from '../../Imagenes/Icons/libro.png' 
import icon12 from '../../Imagenes/Icons/tarjetas.png' 
import ImageComercial from '../../Imagenes/departamento_comercial.jpeg' 


const FuneralServices = () => {

  useEffect(() => { /*Para ver desde el principio de la página */
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <Header title="Servicios Funerales" image={HeaderImage}>
    Tenemos las mejores alternativas para planes individuales, familiares, corporativos, 
    colectivos, masivos a nivel nacional y con asistencia internacional. 
    ¡Exequiales siempre contigo! 
    </Header>
    <section className="container_funeralservices">
      <h3 className='title_menu_services'>Servicios</h3>
      <div className='menu_icons'>
      <div className="columna">
        <img src={icon1} alt="" />
        <h3 className='title_icons'>Arreglos Florales</h3>
      </div>
      <div className="columna">
        <img src={icon2} alt="" />
        <h3 className='title_icons'>Cafetería</h3>
      </div>
      <div className="columna">
        <img src={icon3} alt="" />
        <h3 className='title_icons'>Cofre</h3>
      </div>
      <div className="columna">
        <img src={icon4} alt="" />
        <h3 className='title_icons'>Cremación</h3>
      </div>
      <div className="columna">
        <img src={icon5} alt="" />
        <h3 className='title_icons'>Salas de Velación</h3>
      </div>
      <div className="columna">
        <img src={icon6} alt="" />
        <h3 className='title_icons'>Servicio Religioso</h3>
      </div>
      <div className="columna">
        <img src={icon7} alt="" />
        <h3 className='title_icons'>Tanatopraxia</h3>
      </div>
      <div className="columna">
        <img src={icon8} alt="" />
        <h3 className='title_icons'>Traslado</h3>
      </div>
      <div className="columna">
        <img src={icon9} alt="" />
        <h3 className='title_icons'>Capilla</h3>
      </div>
      <div className="columna">
        <img src={icon10} alt="" />
        <h3 className='title_icons'>Trámites Legales</h3>
      </div>
      <div className="columna">
        <img src={icon11} alt="" />
        <h3 className='title_icons'>Libro de condolencias</h3>
      </div>
      <div className="columna">
        <img src={icon12} alt="" />
        <h3 className='title_icons'>Tarjetas de agradecimiento</h3>
      </div>
      </div>
    </section>

    <section className="container_funeralservices_bottom">
      <div className='funeralservices_left'>
        <img src={ImageComercial} alt="" />
      </div>
      <div className='funeralservices_right'>
        <h2 className='title_servicesexe'>Somos una empresa del IESS</h2>
        <h2 className='paragraph_staff'>Contamos con los mejores asesores comerciales para brindarte un servicio de calidad, pregunta sobre nuestros planes de asistencia funeraria</h2>
        <a className='btn-services' href="../../#/contact/">Contacto</a>
        <div className='prenecesidad'>
          <h2>Pre-necesidad</h2>
          <h2 className='descripcion_pre'>Brindamos a los clientes la opción de planificar y adquirir los servicios funerarios con anticipación ya que es una forma de ofrecer comodidad y facilidad a las personas que desean organizar y asegurar sus arreglos funerarios de manera previa algún fallecimiento de algún ser querido.</h2>
        </div>
        <div className='ni'>
          <h2>Necesidad Inmediata</h2>
          <h2 className='descripcion_ni'>Brindamos información clara, opciones de contacto directo, asistencia rápida y recursos de apoyo a las personas que requieren servicios funerarios debido a un fallecimiento reciente.</h2>
        </div>
      </div>
    </section>
    </>
  )
}

export default FuneralServices
