import Image from '../Imagenes/exe_quito.jpg'
import Image1 from '../Imagenes/IMG_38211.JPG'
import SectionHead from './SectionHead'
import {GiCutDiamond} from 'react-icons/gi'
import {about} from '../data'
import Card from '../UI/Card'

const About = () => {
  return (
    <section className='about'>
      <div className="container about__container">
        <div className="about__left">
          <div className="about__imagen">
            <img src={Image} alt="Values Imagen" />
          </div>
          <div className="about__image">
            <img src={Image1} alt="Values Imagen" />
          </div>
        </div>
        <div className="about__right">
          <SectionHead icon={<GiCutDiamond/>}  title="Salas de Velación"/>
          <p>Exequiales es una compañia del Instituto Ecuatoriano de Seguridad social 
            para brindar servicios funerarios haciendo uso del Fondo de Auxilio para Funerales. Todos los 
            pensionistas de jubilación, pensionistas de montepío, y afiliados al IESS que tengan por lo menos 
            6 aportes mensuales dentro de los últimos 12 meses anteriores a su fallecimiento, tienen derecho 
            al Fondo de Auxilio para Funerales.
          </p>
            <div className="about__wrapper">
              {
                about.map(({id,  title, desc}) => {
                  return <Card key={id} className="about__value">
                    <h4>{title}</h4>
                    <small>{desc}</small>
                  </Card>
                })
              }
            </div>
        </div>
      </div>
    </section>
  )
}

export default About
