import SectionHead from './SectionHead'
import {FaServicestack} from 'react-icons/fa'
import {programs} from '../data'
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom' 
import {AiFillCaretRight} from 'react-icons/ai'

const Programs = () => {
  return (
    <div>
      <section className="programs">
        <div className="container services__container">
            <SectionHead icon={<FaServicestack/>} title="Servicios" />
        
        <div className="programs__wrapper">
          {
            programs.map(({id, title, info, path}) => {
              return (
                <Card className="programs__program" key={id}>
                  <h4>{title}</h4>
                  <small>{info}</small>
                  <Link to={path} className='btn sm'>Mira nuestros servicios <AiFillCaretRight/> </Link>
                </Card>
              )
            })
          }  
        </div>
        </div>
      </section>
    </div>
  )
}

export default Programs
