import { Card } from 'react-bootstrap'
import Header from '../../components/Header'
import HeaderImage from '../../Imagenes/INSTALACIONES/DSC_3272.jpg'
import HeaderImage1 from '../../Imagenes/blog1.jpg'
import HeaderImage2 from '../../Imagenes/blog2.jpg'
import React, { useState, useEffect } from 'react';

import './blog.css'



const Blog = () => {

  useEffect(() => { /*Para ver desde el principio de la página */
    window.scrollTo(0, 0);
  }, []);

  const [mostrarTexto, setMostrarTexto] = useState(false);

  function toggleTexto() {
    setMostrarTexto(!mostrarTexto);
  }

  const [mostrarTexto1, setMostrarTexto1] = useState(false);

  function toggleTexto1() {
    setMostrarTexto1(!mostrarTexto1);
  }



  return (
    

    <>

    
    <Header title="Blog" image={HeaderImage}>
    Brindamos a los usuarios acceso a la información del blog lo cual les ayuda a obtener contenido relevante y valioso, y a establecer una conexión más profunda con la página web y su temática. 
    </Header>

    <section className="blog">
    <div className="blog__container">
      <Card>
          <div className='blog__seccion'>
          <img className='blogg__img' src={HeaderImage1} alt="" />  
          <h2>Abrazar y sus beneficios</h2> 
          <p>
          El 21 de enero se celebra el día internacional del abrazo y surgió la pregunta ¿Por qué celebrar un día del abrazo?
          </p>
          <div>
           {mostrarTexto &&
              <p>
                En primer lugar, este día de conmemoración fue creado por el psicólogo Kevin Zaborney y la razón no es clara ya que no se sabe a ciencia cierta cómo empezó. Algunas fuentes dicen que este día se creó con el fin de que las personas abracen más y la otra es que este psicólogo vio en los abrazos una solución para aliviar el estrés que muchos padecen. <br/><br/>
                Aunque no se tenga completamente claro el origen de este día lo que sí se conoce con toda seguridad son sus beneficios, ya que que abrazar no solo es una muestra física de cariño, sino también tiene muchos beneficios para la salud mental y emocional. <br/><br/>
                Empezamos a investigar y encontramos que existen diferentes estudios sobre este tema los cuales dicen que hay una relación directa entre abrazar y la disminución de estados de ánimo negativos. Esto sucede porque a nivel biológico aumenta la producción de oxitocina, endorfinas y serotonina. Estas son las conocidas ´´hormonas de la felicidad´´ las cuales generan la sensación de felicidad en el cuerpo, por lo tanto, reducen los niveles de estrés y esto genera la sensación de tranquilidad en las personas. <br/><br/>
                Virginia Sati, psicoterapeuta familiar nos habla sobre la cantidad de abrazos que necesita una persona para sentirse mejor. Ella dice que las personas necesitan de al menos 4 abrazos diarios para aguantar sus responsabilidades, 8 para proporcionar bienestar y 12 para promover crecimiento personal. El abrazo es una muestra de afecto que representa muchos beneficios para la salud mental de las personas así que si conoces a alguien que está en un momento difícil ¡Abrázala! 
              </p>
            }
            <button className='btn-read' onClick={toggleTexto}>
              {mostrarTexto ? 'Leer menos' : 'Leer más'}
            </button>
          </div>     
        </div>
      </Card>
      </div>
      <div className="blog__container">
      <Card>
      <div className='blog__seccion'>
          <img src={HeaderImage2} alt="" />  
          <h2>Prevenir es mejor que curar</h2> 
          <p>
          La importancia de la prevención en la salud y cómo puede ayudar a mejorar la calidad de vida de las personas.
          </p>
          <div>
           {mostrarTexto1 &&
              <p>
                La prevención es un término que se refiere a tomar acción de manera anticipada para evitar que suceda algo negativo en el futuro. En el campo de la salud esta palabra se enfoca en la reducción de la incidencia de enfermedades y lesiones mediante la promoción de estilos de vida saludables, la identificación temprana de factores de riesgo y la implementación de medidas preventivas. <br/><br/>
                A continuación, vamos a profundizar en la importancia de la prevención en la salud y cómo puede ayudar a mejorar la calidad de vida de las personas. <br/><br/>
                La prevención es fundamental para mantener una buena salud y evitar enfermedades a largo plazo. Esto se debe a que las enfermedades crónicas, como la diabetes, la hipertensión y la enfermedad cardíaca, pueden desarrollarse durante años antes de que se manifiesten los síntomas, lo que dificulta su tratamiento y puede llevar a complicaciones graves. Por lo tanto, si se detectan tempranamente, pueden ser tratadas de manera efectiva antes de que se conviertan en un problema mayor. <br/><br/>
                Una ventaja importante de la prevención es que puede ayudar a reducir los costos de atención médica. Las enfermedades crónicas y las lesiones pueden requerir tratamientos costosos, hospitalizaciones y procedimientos médicos, lo que puede ser un gran gasto para las personas. Sin embargo, al prevenir se puede evitar costos y reducir la carga financiera para los individuos. <br/><br/>
                Además, también puede ayudar a mejorar la calidad de vida de las personas. Al mantener una buena salud y prevenir enfermedades, las personas pueden disfrutar de una vida más larga y saludable, con menos dolor y sufrimiento asociados con las enfermedades crónicas. También pueden tener más energía y estar más activos, lo que puede llevar a una mayor satisfacción y felicidad en la vida.
              </p>
            }
            <button className='btn-read' onClick={toggleTexto1}>
              {mostrarTexto1 ? 'Leer menos' : 'Leer más'}
            </button>
          </div>      
        </div>
      </Card>     
      </div>
    </section>
    </>
  )
}

export default Blog
